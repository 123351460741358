<template>
    <div class="card">
        <div class="card-header">
            <div class="row align-items-center justify-content-between">
                <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                    <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Add Customer</h5>
                </div>
            </div>
        </div>
        <div class="card-body">
            <form @submit.prevent="submit">
                <CustomerFieldset v-model="formData" form-id="customer_form" creating />
                <div class="mt-2 d-flex">
                    <button type="submit"
                            class="btn btn-sm btn-falcon-primary ml-auto"
                            :disabled="loading">
                        <span v-if="loading" class="spinner-border spinner-border-sm" aria-hidden="true" />
                        Add Customer
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import { createCustomer } from '../services/CustomerService';
    import CustomerFieldset from '../components/CustomerDetails/CustomerFieldset.vue';

    export default {
        name: 'CreateCustomer',
        components: {
            CustomerFieldset,
        },
        data() {
            return {
                loading: false,
                formData: {
                    name: '',
                    identifier: '',
                    phone: '',
                    email: '',
                    website: '',
                },
            };
        },
        methods: {
            async submit() {
                this.loading = true;
                try {
                    await createCustomer({ ...this.formData });
                    this.$router.push({ name: 'customers' });
                } catch (err) {
                    this.$alerts.danger('Unable to Create New Customer',
                                        'Please check your submission for errors. If the problem persists, please contact support.');
                } finally {
                    this.loading = false;
                }
            },
        },
    };
</script>
